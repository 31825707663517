import { Component, OnInit } from '@angular/core';
import { AsapBaseCRUD, Global, AsapService } from 'asap-crud';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['../../app.component.scss','./evaluate.component.scss']
})
export class EvaluatePageComponent extends AsapBaseCRUD implements OnInit {

    public feedback;
    public locked = false;
    public loadingSVG;
    public clientID;

    constructor(
        public global: Global,
        public service: AsapService,
        public route: ActivatedRoute,
        public router: Router,
        public sanitizer: DomSanitizer
    ) {
        super(global,service);
        this.service.basePath = "api/v2/client";
        this.service.entityName = "feedback/access";

        this.service.http.get("assets/imgs/loading.svg",{headers: {'Accept': 'image/svg+xml'},responseType: 'text'}).subscribe((response) => {
          this.loadingSVG = sanitizer.bypassSecurityTrustHtml(response);
        });

        this.clientID = localStorage.getItem('client_id');

        this.route.params.subscribe((params) => {
            if (params.cpf) {
                let cpf = atob(params.cpf);
                this.service.createResource({cpf:cpf},{query:`client_id=${this.clientID}`}).subscribe((response:any) => {
                    if (response.success == false) {
                        this.global.alert("Feedback indisponível","Não foi encontrado no nosso Sistema nenhum questionário para o CPF informado!","error");
                        this.router.navigate(["/home"]);
                    }
                    else {
                        this.feedback = response.data;
                        this.locked = !this.needEvaluation();
                        if (this.locked) {
                            this.global.alert("Comentarios ya completados","Ya ha completado su encuesta, ¡gracias!","success");
                            this.router.navigate(["/home"]);
                        }
                    }
                });
            }
        });
    }

    ngOnInit() {

    }

    public needEvaluation() {
        let needEval = this.feedback.form.filter((item) => {
            if (this.stars(item) == 0) {
                return item;
            }
        });
        if (needEval.length <= 0) this.locked = true;
        return (needEval.length > 0);
    }

    public evaluate(question,rating) {
        this.service.entityName = "feedback/answers";
        let data = {
            user_id: this.feedback.user[0].id,
            quest_id: question.id,
            answers: rating
        }
        this.service.createResource(data,{query:`client_id=${this.clientID}`}).subscribe((response:any) => {
            console.log("Evaluate",response);
            if (question.answers) {
                if (question.answers.length > 0) {
                    question.answers[0].answers = rating;
                }
                else {
                    question.answers.push({
                        answers: rating
                    });
                }
            }
        });
        this.service.entityName = "feedback/access";
        console.log("Evaluate",event);
    }

    public stars(question) {
        if (question.answers) {
            if (question.answers.length > 0) {
                return question.answers[0].answers;
            }
        }
        return 0;
    }

}
