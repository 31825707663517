import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { slider } from './route-animations';
import { environment } from 'src/environments/environment';
import { ThemeService } from './services/theme.service';
import { ClienteService } from './services/client.service';
import { Global } from 'asap-crud';
import { Title } from '@angular/platform-browser';
import { AsapClientPipe } from './services/asap-client.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ ClienteService ],
  animations: [
    slider,
  ]
})
export class AppComponent {

  @ViewChild('outlet',{static:false}) outlet;

  constructor(
    public  theme: ThemeService,
    private client: ClienteService,
    private global: Global,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {
    let host = document.referrer;

    if (location.href.includes('localhost') || host.includes('localhost')) {
       host = "dev.portal.hmgasap.com.br";
      // host = "bemol.portal.hggasap.com.br";
    }

    let themeFunction;
    let client_id;

    client_id = this.global.decodeQueryString().client_id;

    if (!client_id) {
      client_id = localStorage.getItem('client_id');
    }

    if (client_id) {
      localStorage.setItem('client_id',client_id);
      themeFunction = this.client.getColorByID(client_id)
    } else {
        themeFunction = this.client.getColorByHost(host);
    }

    this.loadTheme(themeFunction);

  }

  prepareRoute(outlet: RouterOutlet) {
    localStorage.setItem("apiURL", environment.apiURL);
    this.outlet = outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
    return this.outlet;
  }

  loadTheme(fn) {
    fn.subscribe((response: any) => {
      console.log('Color Theme:', response);
      if (response.data) {
        localStorage.setItem('config_color', JSON.stringify(response.data));

        const dictionary = response.data.config;
        if (dictionary) {
          AsapClientPipe.setDictionary(dictionary);

          const html = document.querySelector('html');

          html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);

          html.style.setProperty('--bg-img-form-box', `url(${AsapClientPipe.dictionary.management.logo})`);

          html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

          html.style.setProperty('--bg-navbar', AsapClientPipe.dictionary.bgNavbar);
          html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.linkNavbar);

          html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgSidebar);
          html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);

          html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
          html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);

          html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.bgBtn);
          html.style.setProperty('--text-btn', AsapClientPipe.dictionary.textBtn);

          // Não mexa nas variáveis do visualizador sem a permissão do Sérgio
          // Quem mexer é corno!
          html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
          html.style.setProperty('--bg-btn-sidebar-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
          html.style.setProperty('--bg-btn-sidebar-viewer-text-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
          html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);
          html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.linkSidebarViewer);

          html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
          html.style.setProperty('--text-btn-viewer', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
          html.style.setProperty('--text-btn-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
          html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);

          html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
          html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);

          html.style.setProperty('--app-logo', AsapClientPipe.dictionary.logoDark);

          // html.style.setProperty('--app-logo-white', AsapClientPipe.dictionary.appLogoWhite);
          // html.style.setProperty('--app-logo', "url('" + AsapClientPipe.dictionary.appLogoWhite + "')");

          console.log('Firebase data Loaded ', AsapClientPipe.dictionary.firebase);

          // console.log('myPage', this.myPage.option);

          // Guardando váriavel de configuração de Linguagem
          localStorage.setItem('language', AsapClientPipe.dictionary.language);
          // tslint:disable-next-line: max-line-length
          localStorage.setItem('imageURL', `https://ui-avatars.com/api/?background=${AsapClientPipe.dictionary.bgBtn}&color=${AsapClientPipe.dictionary.textBtn}&size=400&bold=true&name=`)

          setTimeout(() => {
            this.title.setTitle(AsapClientPipe.dictionary.appName);
            // $('#favicon').attr('href', AsapClientPipe.dictionary.favicon);
            this.theme.theme = response.data;
            console.log('Theme', this.theme.theme);
            this.theme.themeLoaded = true;
            let redirect = localStorage.getItem('redirect_url');
            localStorage.removeItem('redirect_url');
            if (redirect) {
              console.log(`Redirecionando para ${redirect} ...`, new Date());
              this.router.navigate([redirect]);
            }
          }, 100);
        }
        else {
          setTimeout(() => {
            // firebaseLoaded = true;
            this.title.setTitle('Cliente não Encontrado!');
            this.theme.themeLoaded = true;
            this.theme.clientNotFound = true;
            this.router.navigate(['/not-found']);
          }, 100);
        }
      }
      else {
        setTimeout(() => {
          // firebaseLoaded = true;
          this.title.setTitle('Cliente não Encontrado!');
          this.theme.themeLoaded = true;
          this.theme.clientNotFound = true;
          this.router.navigate(['/not-found']);
        }, 100);
      }
    });
  }
}
