import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomePageComponent implements OnInit {

    public cpf = "";

    constructor(
        public router: Router
    ) { }

    ngOnInit() {

    }

    public startEvaluation() {
        let cpf = btoa(this.cpf);
        this.router.navigate(['/evaluate',cpf]);
    }

}
