import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EvaluatePageComponent } from './pages/evaluate/evaluate.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { NgxStarsModule } from 'ngx-stars';
import { HomePageComponent } from './pages/home/home.component';
import { AsapCrudModule, Global } from 'asap-crud';
import { HttpClientModule } from '@angular/common/http';
import { ThemeService } from './services/theme.service';
import { AsapClientPipe } from './services/asap-client.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({}),
    ReactiveFormsModule,
    NgxInputStarRatingModule,
    NgxStarsModule,
    AsapCrudModule
  ],
  declarations: [
    AppComponent,
    HomePageComponent,
    EvaluatePageComponent,
    AsapClientPipe
  ],
  exports: [
    AsapCrudModule
  ],
  providers: [
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
