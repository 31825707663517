import { AsapClientPipe } from './asap-client.pipe';
import { Injectable } from '@angular/core';
import { AsapBaseService } from 'asap-crud';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ThemeService extends AsapBaseService {

    public theme;
    public themeLoaded;
    public clientNotFound = false;

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http);
        this.basePath = 'api/v2';
        this.entityName = '';
    }

    public dictionary() {
        return AsapClientPipe.dictionary;
    }

    public useEAD() {
        if ((this.theme.layout == 1) || (this.theme.layout == 4)) {
            return true;
        }
        if (this.theme.config && this.theme.config.template) {
            if (this.theme.config.template.platforms.includes('elearning')) {
                return true;
            }
            if (this.theme.config.template.platforms.includes('events')) {
                return true;
            }
        }
        return false;
    }


    public useManagement(id) {
        // let ids = [3916, 11268, 16268, 6438, 11332, 11411, 24031, 3907, 3045, 4587, 15256];
        // for (let i = 0; i < ids.length; i++) {
        //     if (id == ids[i]) {
        //        return true;
        //     }
        // }

        // if (id == [3045]) {
        //     return true;
        // }
        // if (this.theme.config && this.theme.config.template) {
        //     if (this.theme.config.template.platforms.includes('gestao')) {
        //         return true;
        //     }
        // }
        // return false;


        if (this.global.loggedUser()) {
            let user = this.global.loggedUser();
            if (user.extras[0]) {
                let userValue = JSON.parse(user.extras[0].value);
                if ((userValue.cargos == 'regional') || (userValue.cargos == 'lider') || (userValue.cargos == 'Marketing') || (userValue.cargos == 'escritorio')) {
                    return true;
                }
            }
        }
    }

    public usePortal() {
        if (this.theme.config && this.theme.config.template) {
            if (this.theme.config.template.platforms.includes('portal')) {
                return true;
            }
        }
        if (this.theme.layout == 5) {
            return true;
        }
        else if (this.theme.layout == 4) {
            if (!this.dictionary().templateType) {
                return true;
            }
            else if (this.dictionary().templateType == 'full') {
                return true;
            }
            else if ((this.global.loggedUser()) && (this.dictionary().tastingUsers.indexOf(this.global.loggedUser().email) >= 0)) {
                return true;
            }
        }
        else {
            return false;
        }
        return false;
    }

}
